<template>
  <b-notification type="is-danger" :closable="false">
    <p>
      This site was deleted on <strong>{{ dateDeleted }}</strong
      >. All data relating to this site (notes, secrets, reports etc) will be
      permanently erased <strong>{{ periodToDeletion }}</strong
      >.
    </p>
    <button
      class="button is-white is-rounded has-margin-y-50"
      :class="{ 'is-loading': isProcessing }"
      :disabled="isProcessing"
      @click="restoreSite"
    >
      Restore
    </button>
  </b-notification>
</template>

<script>
export default {
  name: "SiteDeletedNotification",
  props: {
    site: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isProcessing: false
    };
  },
  computed: {
    dateDeleted() {
      if (!this.site.dateDeleted) return "???";
      const dateDeleted = this.site.dateDeleted.toDate();
      return this.$moment(dateDeleted).format("MMMM Do, Y");
    },
    periodToDeletion() {
      if (!this.site.dateDeleted) return "???";
      const dateNow = this.$moment(new Date());
      const dateDeleted = this.site.dateDeleted.toDate();
      const hardDeleteDate = this.$moment(dateDeleted)
        .add(7, "days")
        .endOf("day");
      return hardDeleteDate.calendar(null, {
        sameDay: "[today]",
        nextDay: "[tomorrow]",
        nextWeek: `[in ${hardDeleteDate.diff(dateNow, "days")} days]`,
        sameElse: `[in ${hardDeleteDate.diff(dateNow, "days")} days]`
      });
    }
  },
  methods: {
    restoreSite() {
      this.isProcessing = true;
      this.$store
        .dispatch("sites/restoreSite", { siteId: this.site._id })
        .then(result => {
          this.$emit("close");
          this.$toast.open({
            message: result.message
          });
        })
        .catch(error => {
          this.isProcessing = false;
          this.$toast.open({
            message: `${error.message}`,
            type: "is-danger"
          });
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@sass/bulma/custom-variables";
@include tablet {
  ::v-deep .media-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>
